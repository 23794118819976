<template lang="pug">
.vc-product-info-card
  .header-bar
    .info-column
      .allow-backorder(v-if="product.is_allow_backorder")
        span.tag {{ attributeLocaleText('product/variant', 'is_allow_backorder') }}
      h1.name {{ product.name }}
      a.brand(
        :href="brandLink"
        v-if="product.brand_id"
      ) {{ product.brand_name }}
      .spec-description(
        v-if="isVariantsLoaded && !!selectedVariant.description"
      )
        .inner-wrapper(v-html="simpleFormat(selectedVariant.description)")

  .options(v-if="variants.length > 0")
    .variant-selector.option-unit(v-if="variants.length > 1")
      .label {{ attributeLocaleText('order/item', 'variant') }}
      template(v-if="product.is_allow_backorder")
        b-dropdown.content.has-message(
          v-model="selectedVariantId"
          @change="variantChangedHandler"
        )
          .variants-dropdown(
            role="button"
            slot="trigger"
          )
            .variant-name {{ selectedVariant.displayName() }}
            .clickable-indicator
              .icon
                i.fa.fa-chevron-down

          b-dropdown-item(
            v-for="variant in variants"
            :key="variant.id"
            :value="variant.id"
          )
            .message
              template(v-if="variant.is_allow_backorder")
                span.allow-backorder(v-if="variant.is_allow_backorder") {{ attributeLocaleText('product/variant', 'is_allow_backorder') }}
              template(v-else)
                span.sold-out(v-if="isVariantSoldOut(variant)") {{ variantStockStatus(variant) }}
            span.name {{ variant.name }}
            .icon
              i.fa.fa-circle-o
      template(v-else)
        b-dropdown.content(
          v-model="selectedVariantId"
          @change="variantChangedHandler"
          :class="{ 'has-message': variants.some((variant) => variant.stock < 1) }"
        )
          .variants-dropdown(
            role="button"
            slot="trigger"
          )
            .variant-name {{ selectedVariant.displayName() }}
            .clickable-indicator
              .icon
                i.fa.fa-chevron-down

          b-dropdown-item(
            v-for="variant in variants"
            :key="variant.id"
            :value="variant.id"
          )
            .message
              span.sold-out(v-if="isVariantSoldOut(variant)") {{ variantStockStatus(variant) }}
            span.name {{ variant.name }}
            .icon
              i.fa.fa-circle-o
    .quantity-selector.option-unit
      .label {{ attributeLocaleText('order/item', 'quantity') }}
      quantity-picker.content(
        :quantity.sync="quantity"
        :current-stock="currentStock"
        :is-allow-backorder="selectedVariant.is_allow_backorder"
      )

  .offers-info(v-if="variants.length > 0")
    .info-unit.price
      .label
        span {{ attributeLocaleText('product', 'price') }}
      .price
        span.type(:data-currency="currentCurrency") {{ toMoney(selectedVariant.original_price).format() }}
        span.type(:data-currency="currentCurrency") {{ variantShipPrice.format() }}
    .info-unit.price
      .label {{ attributeLocaleText('product', 'sub_total') }}
      .price
        span.type(:data-currency="currentCurrency") {{ subTotal.format() }}

  .action-bar
    template(v-if="isPurchasable")
      .button.is-odd.is-outlined(
        :class="{ 'is-loading': isProcessing }"
        @click="buyNow"
      ) {{ actionLocaleText('buy_now') }}
      .button.is-odd(
        :class="{ 'is-loading': isProcessing }"
        @click="addToCart"
      ) {{ actionLocaleText('add_to_cart') }}
    template(v-else)
      .button.is-light(disabled) {{ copyLocaleText('stock_status.no_stock') }}
</template>

<script>
import promoterEventVariantMixin from '../mixins/promoter_event_variant_mixin.js'
import productVariantSelectableMixin from '../mixins/product_variant_selectable_mixin.js'
import Form from 'odd-form_object'

export default {
  components: {},

  mixins: [productVariantSelectableMixin, promoterEventVariantMixin],

  props: {
    variants: {
      type: Array,
      required: true
    }
  },

  // data() {
  //   return {}
  // },

  computed: {
    _variantShipCompareTarget() {
      return this.selectedVariant
    },

    brandLink() {
      return `/brands/${this.product.brand_id}`
    },

    selectedVariant() {
      return this.variants.find(
        (variant) => variant.id === this.selectedVariantId
      )
    },

    requestBody() {
      return {
        data: {
          type: 'order_items',
          attributes: {
            variant_id: this.selectedEventVariantShip.variant_id,
            promoter_share_variant_ship_id: this.selectedShareVariantShip.id,
            quantity: this.quantity
          }
        }
      }
    },

    subTotal() {
      return this.variantShipPrice.multiply(this.quantity)
    },

    isPurchasable() {
      if (!this.selectedVariant) return false

      const shareVariantShip = this.shareVariantShipFor(this.selectedVariant)
      if (shareVariantShip && shareVariantShip.is_sold_out) return false

      return this.selectedVariant.stock > 0
    }
  },

  watch: {
    masterVariant(newValue) {
      this.selectedVariantId = newValue.id
    },

    selectedVariantId() {
      this.quantity = 1
    }
  },

  // created() {},
  mounted() {
    this._setPaddingBottom()
    this._initializeCartService()
  },

  methods: {
    variantChangedHandler() {
      this.$nextTick(() => this.$emit('variant-changed', this.selectedVariant))
    },

    _setPaddingBottom() {
      const actionBarHeight = this.$el.querySelector('.action-bar').offsetHeight

      this.$el.style.paddingBottom = `${actionBarHeight}px`
    },

    addToCart() {
      this._callAddToCartMethod()
        .then(() => {
          this.isProcessing = false
          this.quantity = 1
          this.selectedVariantId = this.masterVariant.id
        })
        .catch((errors) => {
          this.isProcessing = false
        })
    },

    buyNow() {
      this._callAddToCartMethod()
        .then((_) => {
          this.$vlf.setItem('redirect-path', window.location.href)
          Turbolinks.visit('/cart')
        })
        .catch((errors) => {
          this.isProcessing = false
        })
    },

    isVariantSoldOut(variant) {
      return variant.stock < 1
    },

    variantStockStatus(variant) {
      const shareVariantShip = this.shareVariantShipFor(variant)
      if (shareVariantShip && shareVariantShip.is_sold_out)
        return this.copyLocaleText('stock_status.no_stock')

      return this.copyLocaleText(`stock_status.${variant.stock_status}`)
    },

    shareVariantShipFor(variant) {
      return this.shareVariantShips.find((ship) => {
        return ship.variant_id === parseInt(variant.id)
      })
    }
  }
}
</script>
